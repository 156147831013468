<template>
  <!-- begin sidebar nav -->
  <ul class="nav" v-if="filteredSidebarMenu">
    <li class="nav-search" v-if="pageOptions.pageSidebarSearch">
      <input
        type="text"
        v-on:keyup="handleSidebarFilter"
        class="form-control"
        placeholder="Sidebar menu filter..."
      />
    </li>
    <li class="nav-header">{{ userLevel }} Navigation</li>
    <template v-for="menu in filteredSidebarMenu">
      <sidebar-nav-list
        ref="sidebarNavList"
        v-bind:menu="menu"
        v-bind:scrollTop="scrollTop"
        v-bind:key="menu.path"
        v-bind:status="menu.status"
        v-on:collapse-other="handleCollapseOther(menu)"
        v-on:show-float-submenu="handleShowFloatSubmenu"
        v-on:hide-float-submenu="handleHideFloatSubmenu"
      ></sidebar-nav-list>
    </template>
  </ul>
</template>

<script>
import SidebarMenu from "./SidebarMenu.vue";
import SidebarNavList from "./SidebarNavList.vue";
import PageOptions from "../../config/PageOptions.vue";

export default {
  name: "SidebarNav",
  props: ["scrollTop"],
  components: {
    SidebarNavList,
  },
  data() {
    return {
      userLevel: "User",
      sidebarMenu: SidebarMenu,
      pageOptions: PageOptions,
    };
  },
  created() {
    const user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
      this.userLevel = this.user.level;
    } else {
      //
    }
  },
  methods: {
    handleShowFloatSubmenu: function (menu, offset) {
      this.$emit("show-float-submenu", menu, offset);
    },
    handleHideFloatSubmenu: function () {
      this.$emit("hide-float-submenu");
    },
    handleCollapseOther: function (menu) {
      for (var i = 0; i < this.filteredSidebarMenu.length; i++) {
        this.$refs.sidebarNavList[i].collapse(menu);
      }
    },
    handleSidebarMinify: function () {
      this.pageOptions.pageSidebarMinified =
        !this.pageOptions.pageSidebarMinified;
    },
    handleSidebarFilter: function (e) {
      var value = e.target.value;
      value = value.toLowerCase();

      if (value) {
        for (var x = 0; x < this.filteredSidebarMenu.length; x++) {
          var title = this.filteredSidebarMenu[x].title.toLowerCase();
          var children = this.filteredSidebarMenu[x].children;

          if (title.search(value) > -1) {
            this.$refs.sidebarNavList[x].show();

            if (children) {
              this.$refs.sidebarNavList[x].searchExpand();
            }
          } else {
            if (children) {
              var hasActive = false;
              for (var y = 0; y < children.length; y++) {
                var title2 = children[y].title.toLowerCase();

                if (title2.search(value) > -1) {
                  hasActive = true;
                  this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].show();
                  this.$refs.sidebarNavList[x].searchExpand();
                } else {
                  if (hasActive) {
                    this.$refs.sidebarNavList[x].searchExpand();
                  } else {
                    this.$refs.sidebarNavList[x].hide();
                  }
                  this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].hide();
                }
              }
            } else {
              this.$refs.sidebarNavList[x].hide();
            }
          }
        }
      } else {
        for (var a = 0; a < this.filteredSidebarMenu.length; a++) {
          this.$refs.sidebarNavList[a].show();

          var submenu = this.filteredSidebarMenu[a].children;
          if (submenu) {
            for (var b = 0; b < submenu.length; b++) {
              this.$refs.sidebarNavList[a].$refs.sidebarNavList[b].show();
            }
          }
        }
      }
      console.log("------");
    },
  },
  computed: {
    filteredSidebarMenu() {
      const filterMenu = (menu) => {
        if (menu.path === "/dashboard") {
          if (
            this.userLevel !== "Admin" &&
            this.userLevel !== "Operator" &&
            this.userLevel !== "User"
          ) {
            return false;
          }
        } else if (menu.path === "/saksi") {
          if (this.userLevel !== "Admin" && this.userLevel !== "Operator") {
            return false;
          }
        } else if (menu.path === "/paslon") {
          if (this.userLevel !== "Admin" && this.userLevel !== "Operator") {
            return false;
          }
        } else if (menu.path === "/perolehan-suara") {
          if (this.userLevel !== "Admin" && this.userLevel !== "Operator") {
            return false;
          }
        } else if (menu.path === "/laporan-kecurangan") {
          if (this.userLevel !== "Admin" && this.userLevel !== "Operator") {
            return false;
          }
        }

        if (menu.children && menu.children.length > 0) {
          menu.children = menu.children.filter(filterMenu);
        }

        return true; // Menu yang lolos penyaringan akan ditampilkan
      };
      return this.sidebarMenu.filter(filterMenu);
    },
  },
};
</script>
