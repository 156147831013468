var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register register-with-news-feed"},[_vm._m(0),_c('div',{staticClass:"right-content"},[_c('div',{staticClass:"headers d-md-none"}),_vm._m(1),_vm._m(2),_c('div',{staticClass:"register-content"},[_c('form',{attrs:{"id":"formPeserta","enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.postData.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nama_lengkap),expression:"form.nama_lengkap"}],staticClass:"form-control m-b-5",class:{
                  'is-invalid': _vm.formValidate.nama_lengkap,
                },attrs:{"type":"text","name":"nama_lengkap","placeholder":"Nama Lengkap"},domProps:{"value":(_vm.form.nama_lengkap)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nama_lengkap", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(4),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.no_handphone),expression:"form.no_handphone"}],staticClass:"form-control m-b-5",class:{
                  'is-invalid': _vm.formValidate.no_handphone,
                },attrs:{"type":"text","name":"no_handphone","placeholder":"Nomor Ponsel/Whatsapp"},domProps:{"value":(_vm.form.no_handphone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "no_handphone", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6 col-xs-6"},[_c('div',{staticClass:"form-group"},[_vm._m(5),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.formValidate.password,
                },attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('small',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.password)}})])]),_c('div',{staticClass:"col-md-6 col-xs-6"},[_c('div',{staticClass:"form-group"},[_vm._m(6),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirm_password),expression:"form.confirm_password"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.formValidate.confirm_password,
                },attrs:{"type":"password","placeholder":"Ulangi Password"},domProps:{"value":(_vm.form.confirm_password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "confirm_password", $event.target.value)}}}),_c('small',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.confirm_password)}})])]),_c('div',{staticClass:"col-md-6 col-xs-6"},[_c('div',{staticClass:"form-group"},[_vm._m(7),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.provinsi),expression:"form.provinsi"}],staticClass:"form-control m-b-5",class:{
                  'is-invalid': _vm.formValidate.provinsi,
                },attrs:{"name":"provinsi"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "provinsi", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getKabupaten($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiProvinsi),function(provinsi){return _c('option',{key:provinsi.nama_provinsi,domProps:{"value":provinsi.nama_provinsi}},[_vm._v(" "+_vm._s(provinsi.nama_provinsi)+" ")])})],2)])]),_c('div',{staticClass:"col-md-6 col-xs-6"},[_c('div',{staticClass:"form-group"},[_vm._m(8),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kabupaten),expression:"form.kabupaten"}],staticClass:"form-control m-b-5",class:{
                  'is-invalid': _vm.formValidate.kabupaten,
                },attrs:{"name":"kabupaten"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kabupaten", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getKecamatan($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiKabupaten),function(kabupaten){return _c('option',{key:kabupaten.nama_kabupaten,domProps:{"value":kabupaten.nama_kabupaten}},[_vm._v(" "+_vm._s(kabupaten.nama_kabupaten)+" ")])})],2)])]),_c('div',{staticClass:"col-md-46 col-xs-6"},[_c('div',{staticClass:"form-group"},[_vm._m(9),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kecamatan),expression:"form.kecamatan"}],staticClass:"form-control m-b-5",class:{
                  'is-invalid': _vm.formValidate.kecamatan,
                },attrs:{"name":"kecamatan"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kecamatan", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getKelurahan($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiKecamatan),function(kecamatan){return _c('option',{key:kecamatan.nama_kecamatan,domProps:{"value":kecamatan.nama_kecamatan}},[_vm._v(" "+_vm._s(kecamatan.nama_kecamatan)+" ")])})],2)])]),_c('div',{staticClass:"col-md-6 col-xs-6"},[_c('div',{staticClass:"form-group"},[_vm._m(10),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kelurahan),expression:"form.kelurahan"}],staticClass:"form-control m-b-5",class:{
                  'is-invalid': _vm.formValidate.kelurahan,
                },attrs:{"name":"kelurahan"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kelurahan", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiKelurahan),function(kelurahan){return _c('option',{key:kelurahan.nama_kelurahan,domProps:{"value":kelurahan.nama_kelurahan}},[_vm._v(" "+_vm._s(kelurahan.nama_kelurahan)+" ")])})],2)])]),_c('div',{staticClass:"col-md-6 col-xs-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"rw"}},[_vm._v("RW/Dusun")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.rw),expression:"form.rw"}],staticClass:"form-control m-b-5",class:{
                  'is-invalid': _vm.formValidate.rw,
                },attrs:{"type":"text","name":"rw","placeholder":"Contoh: 001"},domProps:{"value":(_vm.form.rw)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "rw", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-6 col-xs-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tps"}},[_vm._v("TPS")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tps),expression:"form.tps"}],staticClass:"form-control m-b-5",class:{
                  'is-invalid': _vm.formValidate.tps,
                },attrs:{"type":"text","name":"tps","placeholder":"Contoh: 001"},domProps:{"value":(_vm.form.tps)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "tps", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"checkbox checkbox-css"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tim_relawan_tps),expression:"form.tim_relawan_tps"}],attrs:{"type":"checkbox","id":"tim_relawan_tps","name":"tim_relawan_tps"},domProps:{"checked":Array.isArray(_vm.form.tim_relawan_tps)?_vm._i(_vm.form.tim_relawan_tps,null)>-1:(_vm.form.tim_relawan_tps)},on:{"change":function($event){var $$a=_vm.form.tim_relawan_tps,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "tim_relawan_tps", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "tim_relawan_tps", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "tim_relawan_tps", $$c)}}}}),_c('label',{attrs:{"for":"tim_relawan_tps"}},[_vm._v("Saya bersedia mencoblos Anies Baswedan - Muhaimin Iskandar dan menjadi Saksi di TPS (Tempat Pemungutan Suara)")])])])]),_c('div',{staticClass:"register-buttons m-t-30"},[_c('button',{staticClass:"btn btn-success btn-block btn-lg",attrs:{"type":"button","disabled":this.loading},on:{"click":function($event){return _vm.postData()}}},[(this.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" REGISTRASI ")])]),_c('div',{staticClass:"m-t-20 m-b-20 p-b-20 text-inverse",staticStyle:{"font-size":"14px"}},[_vm._v(" Sudah memiliki akun? klik "),_c('router-link',{attrs:{"to":"/login/"}},[_vm._v("Login")]),_vm._v(" untuk login. ")],1),_c('hr'),_c('p',{staticClass:"text-center"},[_vm._v("© Saksi Amin All Right Reserved 2023")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-feed"},[_c('div',{staticClass:"news-image",staticStyle:{"background-image":"url(/assets/img/bg-1.jpg)"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-header"},[_c('div',{staticClass:"brand text-center"},[_c('img',{staticStyle:{"width":"100px","margin-top":"20px"},attrs:{"src":"/assets/img/logo-color.png","alt":"Logo"}}),_c('h3',{staticClass:"mt-3"},[_vm._v("SAKSI AMIN")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"register-header text-center"},[_c('small',[_vm._v("Mohon mengisi data anda dengan baik dan benar.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"nama_lengkap"}},[_vm._v("Nama Lengkap "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"control-label"},[_vm._v("Nomor Ponsel/Whatsapp "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"password"}},[_vm._v("Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"confirm_password"}},[_vm._v("Ulangi Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"provinsi"}},[_vm._v("Provinsi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"kabupaten"}},[_vm._v("Kabupaten "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"kecamatan"}},[_vm._v("Kecamatan "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"kelurahan"}},[_vm._v("Kelurahan/Desa "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
}]

export { render, staticRenderFns }