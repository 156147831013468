<template>
  <div class="register register-with-news-feed">
    <div class="news-feed">
      <div
        class="news-image"
        style="background-image: url(/assets/img/bg-1.jpg)"
      ></div>
    </div>
    <div class="right-content">
      <div class="headers d-md-none"></div>
      <div class="login-header">
        <div class="brand text-center">
          <img
            src="/assets/img/logo-color.png"
            alt="Logo"
            style="width: 100px; margin-top: 20px"
          />
          <h3 class="mt-3">SAKSI AMIN</h3>
        </div>
      </div>
      <h1 class="register-header text-center">
        <small>Mohon mengisi data anda dengan baik dan benar.</small>
      </h1>
      <div class="register-content">
        <form
          @submit.prevent="postData"
          id="formPeserta"
          enctype="multipart/form-data"
        >
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="nama_lengkap"
                  >Nama Lengkap <span class="text-danger">*</span></label
                >
                <input
                  v-model="form.nama_lengkap"
                  type="text"
                  name="nama_lengkap"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.nama_lengkap,
                  }"
                  placeholder="Nama Lengkap"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label"
                  >Nomor Ponsel/Whatsapp
                  <span class="text-danger">*</span></label
                >
                <input
                  v-model="form.no_handphone"
                  type="text"
                  name="no_handphone"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.no_handphone,
                  }"
                  placeholder="Nomor Ponsel/Whatsapp"
                />
              </div>
            </div>

            <div class="col-md-6 col-xs-6">
              <div class="form-group">
                <label for="password"
                  >Password <span class="text-danger">*</span></label
                >
                <input
                  type="password"
                  v-model="form.password"
                  class="form-control"
                  placeholder="Password"
                  :class="{
                    'is-invalid': formValidate.password,
                  }"
                />
                <small
                  class="text-danger"
                  v-html="formValidate.password"
                ></small>
              </div>
            </div>
            <div class="col-md-6 col-xs-6">
              <div class="form-group">
                <label for="confirm_password"
                  >Ulangi Password <span class="text-danger">*</span></label
                >
                <input
                  type="password"
                  v-model="form.confirm_password"
                  class="form-control"
                  placeholder="Ulangi Password"
                  :class="{
                    'is-invalid': formValidate.confirm_password,
                  }"
                />
                <small
                  class="text-danger"
                  v-html="formValidate.confirm_password"
                ></small>
              </div>
            </div>

            <div class="col-md-6 col-xs-6">
              <div class="form-group">
                <label for="provinsi"
                  >Provinsi <span class="text-danger">*</span></label
                >
                <select
                  v-model="form.provinsi"
                  name="provinsi"
                  class="form-control m-b-5"
                  v-on:change="getKabupaten($event)"
                  :class="{
                    'is-invalid': formValidate.provinsi,
                  }"
                >
                  <option value="">Pilih</option>
                  <option
                    v-for="provinsi in opsiProvinsi"
                    :key="provinsi.nama_provinsi"
                    :value="provinsi.nama_provinsi"
                  >
                    {{ provinsi.nama_provinsi }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-6 col-xs-6">
              <div class="form-group">
                <label for="kabupaten"
                  >Kabupaten <span class="text-danger">*</span></label
                >
                <select
                  v-model="form.kabupaten"
                  name="kabupaten"
                  class="form-control m-b-5"
                  v-on:change="getKecamatan($event)"
                  :class="{
                    'is-invalid': formValidate.kabupaten,
                  }"
                >
                  <option value="">Pilih</option>
                  <option
                    v-for="kabupaten in opsiKabupaten"
                    :key="kabupaten.nama_kabupaten"
                    :value="kabupaten.nama_kabupaten"
                  >
                    {{ kabupaten.nama_kabupaten }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-46 col-xs-6">
              <div class="form-group">
                <label for="kecamatan"
                  >Kecamatan <span class="text-danger">*</span></label
                >
                <select
                  v-model="form.kecamatan"
                  name="kecamatan"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.kecamatan,
                  }"
                  v-on:change="getKelurahan($event)"
                >
                  <option value="">Pilih</option>
                  <option
                    v-for="kecamatan in opsiKecamatan"
                    :key="kecamatan.nama_kecamatan"
                    :value="kecamatan.nama_kecamatan"
                  >
                    {{ kecamatan.nama_kecamatan }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-6 col-xs-6">
              <div class="form-group">
                <label for="kelurahan"
                  >Kelurahan/Desa <span class="text-danger">*</span></label
                >
                <select
                  v-model="form.kelurahan"
                  name="kelurahan"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.kelurahan,
                  }"
                >
                  <option value="">Pilih</option>
                  <option
                    v-for="kelurahan in opsiKelurahan"
                    :key="kelurahan.nama_kelurahan"
                    :value="kelurahan.nama_kelurahan"
                  >
                    {{ kelurahan.nama_kelurahan }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-6 col-xs-6">
              <div class="form-group">
                <label for="rw">RW/Dusun</label>
                <input
                  v-model="form.rw"
                  type="text"
                  name="rw"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.rw,
                  }"
                  placeholder="Contoh: 001"
                />
              </div>
            </div>

            <div class="col-md-6 col-xs-6">
              <div class="form-group">
                <label for="tps">TPS</label>
                <input
                  v-model="form.tps"
                  type="text"
                  name="tps"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.tps,
                  }"
                  placeholder="Contoh: 001"
                />
              </div>
            </div>

            <!-- <div class="col-md-12">
              <div class="form-group">
                <label for="tanggal_lahir"
                  >Tanggal Lahir <span class="text-danger">*</span></label
                >
                <div class="input-group-append">
                  <select
                    v-model="form.tanggal_lahir"
                    class="form-control m-r-3"
                    placeholder="Tanggal Lahir"
                    :class="{ 'is-invalid': formValidate.tanggal_lahir }"
                  >
                    <option value="">Tanggal</option>
                    <option
                      v-for="day in Array.from(
                        { length: 31 },
                        (_, index) => index + 1
                      )"
                      :key="day"
                      :value="String(day).padStart(2, '0')"
                    >
                      {{ String(day).padStart(2, "0") }}
                    </option>
                  </select>

                  <select
                    v-model="form.bulan_lahir"
                    class="form-control"
                    placeholder="Bulan Lahir"
                    :class="{ 'is-invalid': formValidate.bulan_lahir }"
                  >
                    <option value="">Bulan</option>
                    <option
                      v-for="(month, index) in months"
                      :key="index"
                      :value="String(index + 1).padStart(2, '0')"
                    >
                      {{ month }}
                    </option>
                  </select>

                  <select
                    v-model="form.tahun_lahir"
                    class="form-control m-l-3"
                    placeholder="Tahun Lahir"
                    :class="{ 'is-invalid': formValidate.tahun_lahir }"
                  >
                    <option value="">Tahun</option>
                    <option
                      v-for="year in availableYears"
                      :key="year"
                      :value="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                </div>
              </div>
            </div> -->
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="checkbox checkbox-css">
                <input
                  type="checkbox"
                  id="tim_relawan_tps"
                  name="tim_relawan_tps"
                  v-model="form.tim_relawan_tps"
                />
                <label for="tim_relawan_tps"
                  >Saya bersedia mencoblos Anies Baswedan - Muhaimin Iskandar
                  dan menjadi Saksi di TPS (Tempat Pemungutan Suara)</label
                >
              </div>
            </div>
          </div>

          <div class="register-buttons m-t-30">
            <button
              type="button"
              class="btn btn-success btn-block btn-lg"
              @click="postData()"
              :disabled="this.loading"
            >
              <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
              REGISTRASI
            </button>
          </div>
          <div
            class="m-t-20 m-b-20 p-b-20 text-inverse"
            style="font-size: 14px"
          >
            Sudah memiliki akun? klik
            <router-link to="/login/">Login</router-link> untuk login.
          </div>
          <hr />
          <p class="text-center">&copy; Saksi Amin All Right Reserved 2023</p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PageOptions from "../config/PageOptions.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 70;
    const endYear = currentYear - 15;

    const years = [];
    for (let year = endYear; year >= startYear; year--) {
      years.push(year);
    }

    return {
      form: {
        nama_lengkap: "",
        no_handphone: "",
        provinsi: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
        rw: "",
        tps: "",
        password: "",
        confirm_password: "",
        tim_relawan_tps: false,
        tanggal_lahir: "",
        bulan_lahir: "",
        tahun_lahir: "",
      },
      opsiProvinsi: [],
      opsiKabupaten: [],
      opsiKecamatan: [],
      opsiKelurahan: [],
      formValidate: [],
      loading: false,
      availableYears: years,
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    };
  },
  mounted() {
    this.getProvinsi();
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post("/api/auth/registrasi/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            // console.log(response.data.message);
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.$router.push("/login");
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getProvinsi() {
      axios
        .get("/api/daerah/provinsi")
        .then((response) => {
          this.opsiProvinsi = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKabupaten(event) {
      this.form.provinsi = event.target.value;
      console.log(this.form.provinsi);
      axios
        .get("/api/daerah/kabupaten/" + event.target.value)
        .then((response) => {
          this.opsiKabupaten = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKecamatan(event) {
      this.form.kabupaten = event.target.value;
      axios
        .get("/api/daerah/kecamatan/" + event.target.value)
        .then((response) => {
          this.opsiKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKelurahan(event) {
      this.form.kecamatan = event.target.value;
      axios
        .get("/api/daerah/kelurahan/" + event.target.value)
        .then((response) => {
          this.opsiKelurahan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
<style scoped>
.register.register-with-news-feed .right-content {
  padding: 0px;
}
.headers {
  /* clip-path: path("m 0 0 v 100 c 70 30 140 30 210 0 V 0 Z"); */
  height: 160px;
  background-image: url("/assets/img/bg-1.jpg");
  background-size: cover;
  margin-bottom: 20px;
  border-radius: 0 0 50% 50% / 30px;
  border-bottom: solid 5px #2f318b;
}
.register-content {
  padding: 40px;
}

.footers {
  height: 50px;
  background-color: #2f318b;
  /* border-radius: 80px 80px 0px 0px / 30px 30px 0px 0px; */
  background-image: radial-gradient(
    120% 120px at 50% -30px,
    #000 75%,
    transparent 75%
  );
}
</style>