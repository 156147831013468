var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"panel panel-primary"},[_vm._m(0),_c('div',{staticClass:"panel-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"lineNumbers":true,"search-options":{
            enabled: true,
            placeholder: 'Search this table',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 10,
            perPageDropdownEnabled: false,
            position: 'bottom',
            nextLabel: '',
            prevLabel: '',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All',
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'status')?_c('span',[(_vm.isAdmin)?_c('div',{staticClass:"switcher"},[_c('input',{attrs:{"type":"checkbox","name":props.row.id,"id":props.row.id},domProps:{"checked":parseInt(props.row.status_aktif)},on:{"click":function($event){return _vm.toggleUser(props.row.id, props.row.status_aktif)}}}),_c('label',{attrs:{"for":props.row.id}})]):_vm._e()]):_vm._e(),(props.column.field == 'action')?_c('span',[_c('div',[_c('button',{staticClass:"btn btn-sm btn-danger",on:{"click":function($event){return _vm.confirmDelete(props.row.id)}}},[_c('i',{staticClass:"fa fa-trash-alt"})])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-default m-r-4",attrs:{"type":"button"},on:{"click":function($event){return _vm.fetchData()}}},[_c('i',{staticClass:"fa fa-redo"})])])])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Daftar Saksi")])])
}]

export { render, staticRenderFns }