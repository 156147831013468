<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title bold">Laporan Kecurangan</h3>
        </div>
        <form
          @submit.prevent="postData"
          id="formPeserta"
          enctype="multipart/form-data"
        >
          <div class="panel-body">
            <div class="row">
              <div class="col-md-3 col-xs-6">
                <div class="form-group">
                  <label for="provinsi"
                    >Provinsi <span class="text-danger">*</span></label
                  >
                  <select
                    v-model="form.provinsi"
                    name="provinsi"
                    class="form-control m-b-5"
                    v-on:change="getKabupaten($event)"
                    :class="{
                      'is-invalid': formValidate.provinsi,
                    }"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="provinsi in opsiProvinsi"
                      :key="provinsi.nama_provinsi"
                      :value="provinsi.nama_provinsi"
                    >
                      {{ provinsi.nama_provinsi }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3 col-xs-6">
                <div class="form-group">
                  <label for="kabupaten"
                    >Kabupaten <span class="text-danger">*</span></label
                  >
                  <select
                    v-model="form.kabupaten"
                    name="kabupaten"
                    class="form-control m-b-5"
                    v-on:change="getKecamatan($event)"
                    :class="{
                      'is-invalid': formValidate.kabupaten,
                    }"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="kabupaten in opsiKabupaten"
                      :key="kabupaten.nama_kabupaten"
                      :value="kabupaten.nama_kabupaten"
                    >
                      {{ kabupaten.nama_kabupaten }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3 col-xs-6">
                <div class="form-group">
                  <label for="kecamatan"
                    >Kecamatan <span class="text-danger">*</span></label
                  >
                  <select
                    v-model="form.kecamatan"
                    name="kecamatan"
                    class="form-control m-b-5"
                    :class="{
                      'is-invalid': formValidate.kecamatan,
                    }"
                    v-on:change="getKelurahan($event)"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="kecamatan in opsiKecamatan"
                      :key="kecamatan.nama_kecamatan"
                      :value="kecamatan.nama_kecamatan"
                    >
                      {{ kecamatan.nama_kecamatan }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3 col-xs-6">
                <div class="form-group">
                  <label for="kelurahan"
                    >Kelurahan/Desa <span class="text-danger">*</span></label
                  >
                  <select
                    v-model="form.kelurahan"
                    name="kelurahan"
                    class="form-control m-b-5"
                    :class="{
                      'is-invalid': formValidate.kelurahan,
                    }"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="kelurahan in opsiKelurahan"
                      :key="kelurahan.nama_kelurahan"
                      :value="kelurahan.nama_kelurahan"
                    >
                      {{ kelurahan.nama_kelurahan }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-3 col-xs-6">
                <div class="form-group">
                  <label for="tps">TPS</label>
                  <input
                    v-model="form.tps"
                    type="text"
                    name="tps"
                    class="form-control m-b-5"
                    :class="{
                      'is-invalid': formValidate.tps,
                    }"
                    placeholder="Contoh: 001"
                  />
                </div>
              </div>
              <div class="col-md-3 col-xs-6">
                <div class="form-group">
                  <label for="tanggal">Tanggal</label>
                  <input
                    v-model="form.tanggal"
                    type="date"
                    name="tanggal"
                    class="form-control m-b-5"
                    :class="{
                      'is-invalid': formValidate.tanggal,
                    }"
                  />
                </div>
              </div>

              <div class="col-md-3 col-xs-6">
                <div class="form-group">
                  <label for="waktu">Jam</label>
                  <input
                    v-model="form.waktu"
                    type="time"
                    class="form-control m-b-5"
                    :class="{
                      'is-invalid': formValidate.waktu,
                    }"
                  />
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label for="deskripsi">Deskripsi</label>
                  <textarea
                    v-model="form.deskripsi"
                    class="form-control m-b-5"
                    rows="10"
                    :class="{
                      'is-invalid': formValidate.deskripsi,
                    }"
                  ></textarea>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label for="">Bukti Kecurangan</label>
                  <br />
                  <!-- Loop through fileInputs array -->
                  <div v-for="(fileInput, index) in fileInputs" :key="index">
                    <input
                      type="file"
                      :name="'file_dokumen[' + index + ']'"
                      accept="image/*"
                      class="btn btn-sm btn-success m-b-3 m-r-5"
                      @change="onFileChange($event, index)"
                    />
                    <a
                      class="btn btn-danger m-b-3"
                      href="javascript:;"
                      @click="removeFileInput(index)"
                      ><i class="fa fa-trash"></i
                    ></a>
                  </div>
                  <a
                    class="btn btn-info m-b-3"
                    href="javascript:;"
                    @click="addFileInput"
                    >Tambah Gambar Bukti</a
                  >

                  <!-- <br />
                  <input
                    type="file"
                    name="file_dokumen"
                    accept="image/*"
                    class="btn btn-success"
                    ref="fileInput"
                    @change="onFileChange"
                  /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <button
              type="button"
              class="btn btn-success mt-4"
              @click="postData()"
              :disabled="this.loading"
            >
              <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
              {{ $route.params.id ? "Update" : "KIRIM DATA" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style>
.editorError {
  border: 0.5px solid #ff5b57;
}
</style>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      form: {
        provinsi: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
        tps: "",
        deskripsi: "",
        tanggal: 0,
        waktu: 0,
        paslon: {},
      },
      fileInputs: [],
      file: "",
      link: "/api/kecurangan/create",
      formValidate: [],
      loading: false,

      opsiProvinsi: [],
      opsiKabupaten: [],
      opsiKecamatan: [],
      opsiKelurahan: [],
      token: "",
    };
  },
  created() {
    const user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
      this.level = this.user.level;
    } else {
      // this.$router.push("/login");
    }
  },
  mounted() {
    this.getProvinsi();
    this.getPaslon();
    const user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
      this.token = this.user.token;
    }
  },
  methods: {
    addFileInput() {
      this.fileInputs.push(null); // Tambahkan null ke array
    },
    onFileChange(event, index) {
      const file = event.target.files[0];
      if (file) {
        // Simpan file ke dalam array fileInputs
        this.fileInputs[index] = file;
        // Jika Anda ingin melihat file yang telah dipilih
        console.log("File selected:", file);
      } else {
        console.log("No file selected.");
      }
    },

    // addFileInput() {
    //   this.fileInputs.push("fileInput_" + this.fileInputs.length);
    // },
    // addFileInput() {
    //   // Add a new file input element
    //   this.fileInputs.push({});
    // },
    removeFileInput(index) {
      // Remove a specific file input element
      this.fileInputs.splice(index, 1);
    },
    // onFileChange(event) {
    //   const file = event.target.files[0];
    //   this.file = file;
    // },
    postData() {
      // this.loading = true;

      const formData = new FormData();

      for (let i = 0; i < this.fileInputs.length; i++) {
        const file = this.fileInputs[i];
        if (file) {
          formData.append("file_dokumen[]", file);
        }
      }

      // formData.append("file_dokumen", this.file);
      formData.append("provinsi", this.form.provinsi);
      formData.append("kabupaten", this.form.kabupaten);
      formData.append("kecamatan", this.form.kecamatan);
      formData.append("kelurahan", this.form.kelurahan);
      formData.append("tps", this.form.tps);
      formData.append("waktu", this.form.waktu);
      formData.append("tanggal", this.form.tanggal);
      formData.append("deskripsi", this.form.deskripsi);
      // formData.forEach((value, key) => {
      //   console.log(key, value);
      // });
      // const files = formData.getAll("file_dokumen[]");
      // for (let i = 0; i < files.length; i++) {
      //   console.log(i, files[i]);
      // }

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.token,
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              this.$router.go(-1);
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getPaslon() {
      axios
        .get("/api/paslon")
        .then((response) => {
          this.dataPaslon = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getProvinsi() {
      axios
        .get("/api/daerah/provinsi")
        .then((response) => {
          this.opsiProvinsi = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKabupaten(event) {
      this.form.provinsi = event.target.value;
      console.log(this.form.provinsi);
      axios
        .get("/api/daerah/kabupaten/" + event.target.value)
        .then((response) => {
          this.opsiKabupaten = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKecamatan(event) {
      this.form.kabupaten = event.target.value;
      axios
        .get("/api/daerah/kecamatan/" + event.target.value)
        .then((response) => {
          this.opsiKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKelurahan(event) {
      this.form.kecamatan = event.target.value;
      axios
        .get("/api/daerah/kelurahan/" + event.target.value)
        .then((response) => {
          this.opsiKelurahan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>

