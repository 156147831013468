<template>
  <div class="login login-with-news-feed">
    <div class="news-feed">
      <div
        class="news-image"
        style="background-image: url(/assets/img/bg-1.jpg)"
      ></div>
    </div>
    <div class="right-content">
      <div class="headers d-md-none"></div>

      <div class="login-header m-t-5">
        <div class="brand text-center">
          <img
            src="/assets/img/logo-color.png"
            alt="Logo"
            style="width: 100px"
          />
          <h3 class="mt-3">SAKSI AMIN</h3>
          <h4>Selamat Datang</h4>
        </div>
      </div>

      <div class="login-content">
        <form @submit.prevent="login" method="POST" class="margin-bottom-0">
          <div class="form-group m-b-15">
            <input
              type="number"
              class="form-control form-control-lg"
              placeholder="Nomor Ponsel/Whatsapp"
              v-model="username"
              required
            />
          </div>
          <div class="form-group m-b-15">
            <input
              type="password"
              class="form-control form-control-lg"
              placeholder="Password"
              id="password"
              v-model="password"
              required
            />
          </div>
          <div class="checkbox checkbox-css m-b-30">
            <input
              type="checkbox"
              id="remember_me"
              name="remember_me"
              v-model="remember_me"
            />
            <label for="remember_me"> Remember Me </label>
          </div>

          <b-alert v-if="alert == 'error'" variant="danger" show fade>
            <strong>Login Gagal!</strong>
            <br />
            <span>{{ message }}</span>
          </b-alert>
          <b-alert v-if="alert == 'success'" variant="success" show fade>
            <strong>Login Success!</strong>
            <br />
            <span>{{ message }}</span>
          </b-alert>
          <div class="login-buttons">
            <button type="submit" class="btn btn-success btn-block btn-lg">
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              LOGIN
            </button>
          </div>
          <div class="m-t-20 p-b-20 text-inverse text-center">
            Belum terdaftar, klik
            <router-link to="/register">Daftar</router-link>
          </div>
          <hr />
          <p class="text-center text-grey-darker">
            &copy; Saksi Amin All Right Reserved 2023
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PageOptions from "../config/PageOptions.vue";
import axios from "axios";
// import Swal from "sweetalert2";

export default {
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      message: "",
      alert: "",
      remember_me: false,
    };
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    login() {
      this.loading = true;
      const formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);
      formData.append("remember", this.remember_me);

      axios
        .post("/api/auth/login/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        .then((response) => {
          if (response.data.status == true) {
            this.loading = false;
            this.alert = "success";
            this.message = "Selamat Datang " + response.data.nama;
            setTimeout(() => {
              const user = {
                nama: response.data.nama,
                level: response.data.level,
                token: response.data.token,
              };
              localStorage.setItem("user", JSON.stringify(user));
              if (response.data.level === "Admin") {
                this.$router.push("/dashboard");
              } else if (response.data.level === "User") {
                this.$router.push("/dashboard");
              } else {
                this.$router.push("/dashboard");
              }
            }, 2000);
          } else {
            this.loading = false;
            this.alert = "error";
            this.message = response.data.message;
            setTimeout(() => {
              this.alert = "";
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });

      // if (this.username === "admin" && this.password === "admin") {
      //   const user = {
      //     username: this.username,
      //     level: "admin", // Ganti dengan level pengguna yang sesuai
      //   };
      //   localStorage.setItem("user", JSON.stringify(user));
      //   this.$router.push("/dashboard");
      // } else if (this.username === "user" && this.password === "user") {
      //   const user = {
      //     username: this.username,
      //     level: "user", // Ganti dengan level pengguna yang sesuai
      //   };
      //   localStorage.setItem("user", JSON.stringify(user));
      //   this.$router.push("/dashboard");
      // } else {
      //   alert("Username atau password salah");
      // }
    },
  },
};
</script>
<style scoped>
.login.login-with-news-feed .right-content {
  padding: 0px;
}
.headers {
  /* clip-path: path("m 0 0 v 100 c 70 30 140 30 210 0 V 0 Z"); */
  height: 160px;
  background-image: url("/assets/img/bg-1.jpg");
  background-size: cover;
  margin-bottom: 20px;
  border-radius: 0 0 50% 50% / 30px;
  border-bottom: solid 5px #2f318b;
}
.login-content {
  padding: 40px;
}

.footers {
  height: 50px;
  background-color: #2f318b;
  /* border-radius: 80px 80px 0px 0px / 30px 30px 0px 0px; */
  background-image: radial-gradient(
    120% 120px at 50% -30px,
    #000 75%,
    transparent 75%
  );
}
</style>