import Vue from "vue";
import VueRouter from "vue-router";

import axios from "axios";
import Swal from "sweetalert2";

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";
import Login from "../pages/Login.vue";
import Register from "../pages/Register.vue";

import Profil from "../pages/profil/Profil.vue";

import Saksi from "../pages/saksi/Index.vue";
import Paslon from "../pages/paslon/Index.vue";
import Perolehan from "../pages/perolehan/Index.vue";
import Hasil from "../pages/perolehan/Form.vue";

import Lapor from "../pages/lapor/Index.vue";
import LaporForm from "../pages/lapor/Form.vue";
import LaporDetail from "../pages/lapor/Detail.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard/",
  },
  { path: "*", redirect: "/dashboard/" },
  { path: "/dashboard", component: Dashboard },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  {
    path: "/saksi",
    component: Saksi,
    meta: {
      requiredLevel: ["Admin", "Operator"],
    },
  },
  {
    path: "/paslon",
    component: Paslon,
    meta: {
      requiredLevel: ["Admin", "Operator"],
    },
  },
  {
    path: "/perolehan-suara",
    component: Perolehan,
    meta: {
      requiredLevel: ["Admin", "Operator"],
    },
  },
  {
    path: "/suara-tps",
    component: Hasil,
    meta: {
      requiredLevel: ["Admin", "Operator", "User"],
    },
  },
  {
    path: "/profil",
    component: Profil,
    meta: {
      requiredLevel: ["Admin", "Operator"],
    },
  },
  {
    path: "/profil/:page",
    component: Profil,
    meta: {
      requiredLevel: ["Admin", "Operator", "User"],
    },
  },
  {
    path: "/kecurangan-tps",
    component: LaporForm,
    meta: {
      requiredLevel: ["Admin", "Operator", "User"],
    },
  },
  {
    path: "/laporan-kecurangan",
    component: Lapor,
    meta: {
      requiredLevel: ["Admin", "Operator", "User"],
    },
  },
  {
    path: "/laporan-kecurangan/detail/:id",
    component: LaporDetail,
    meta: {
      requiredLevel: ["Admin", "Operator", "User"],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("user");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (to.path !== "/login" && !isLoggedIn && to.path !== "/register") {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden"); // Tambahkan halaman forbidden jika level pengguna tidak sesuai
    } else {
      next();
    }
  } else {
    next();
  }

  const userData = localStorage.getItem("user");
  if (userData) {
    const user = JSON.parse(userData);

    axios
      .get("/api/auth/status/", {
        headers: {
          token: user.token,
        },
      })
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            title: "Info",
            text: "Session Habis, Silahkan Login Kembali",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
          });
          setTimeout(() => {
            Swal.close();
            localStorage.removeItem("user");
            if (to.path !== "/login") {
              next("/login");
            }
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

export default router;
