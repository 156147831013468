<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h1 class="page-header">Dashboard <small></small></h1>
      </div>
      <div class="col-md-12" v-if="level == 'User'">
        <div class="row">
          <div class="col-md-6 m-b-5">
            <router-link
              class="btn btn-danger btn-block btn-lg"
              to="/kecurangan-tps"
              >Lapor Kecurangan</router-link
            >
          </div>
          <div class="col-md-6">
            <router-link
              class="btn btn-primary btn-block btn-lg"
              to="/suara-tps"
              >Lapor Hasil Suara</router-link
            >
          </div>
        </div>
      </div>
      <div class="col-md-12" style="margin-top: 20px">
        <div style="height: 75vh" ref="barMonitor"></div>
      </div>
    </div>
  </div>
</template>

<style>
.background-container {
  position: relative;
  height: 100px; /* Ubah sesuai kebutuhan */
  width: 100%; /* Ubah sesuai kebutuhan */
}

.background-image {
  background: center no-repeat;
  background-size: auto 100%;
}

@supports (background: url("invalid-url")) {
  .background-image {
    background: red; /* Tampilan alternatif ketika gambar tidak didukung atau tidak ada */
  }
}
</style>

<script>
import axios from "axios";
import Highcharts from "highcharts";

export default {
  name: "dashboard",
  data() {
    return {
      nama_paslon: [],
      jumlah_pendukung: [],
      level: "",
    };
  },
  created() {
    this.fetchGrafik();
    setInterval(() => {
      this.fetchGrafik();
    }, 10000);

    const user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
      this.level = this.user.level;
    }
  },
  methods: {
    fetchGrafik() {
      axios
        .get("/api/suara/monitor/")
        .then((response) => {
          this.nama_paslon = response.data.data.map((item) => item.nama_paslon);
          this.jumlah_pendukung = response.data.data.map((item) =>
            parseInt(item.jumlah_suara)
          );
          this.renderBarMonitor();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    renderBarMonitor() {
      const totalSuara = this.jumlah_pendukung.reduce(
        (acc, suara) => acc + suara,
        0
      );
      const persentasePendukung = this.jumlah_pendukung.map((suara) =>
        parseFloat(((suara / totalSuara) * 100).toFixed(2))
      );

      Highcharts.chart(this.$refs.barMonitor, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Grafik Perolehan Suara (dalam Persentase)",
        },
        xAxis: {
          categories: this.nama_paslon,
        },
        yAxis: {
          title: {
            text: "Persentase Suara",
          },
          labels: {
            format: "{value}%",
          },
        },
        series: [
          {
            name: "Persentase Suara",
            data: persentasePendukung, // Gunakan data persentase yang sudah dihitung
            colorByPoint: true,
            dataLabels: {
              format: "{y}%",
            },
          },
        ],
      });
    },
  },
};
</script>