var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"panel panel-primary"},[_vm._m(0),_c('form',{attrs:{"id":"formPeserta","enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.postData.apply(null, arguments)}}},[_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-xs-6"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.provinsi),expression:"form.provinsi"}],staticClass:"form-control m-b-5",class:{
                    'is-invalid': _vm.formValidate.provinsi,
                  },attrs:{"name":"provinsi"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "provinsi", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getKabupaten($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiProvinsi),function(provinsi){return _c('option',{key:provinsi.nama_provinsi,domProps:{"value":provinsi.nama_provinsi}},[_vm._v(" "+_vm._s(provinsi.nama_provinsi)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3 col-xs-6"},[_c('div',{staticClass:"form-group"},[_vm._m(2),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kabupaten),expression:"form.kabupaten"}],staticClass:"form-control m-b-5",class:{
                    'is-invalid': _vm.formValidate.kabupaten,
                  },attrs:{"name":"kabupaten"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kabupaten", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getKecamatan($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiKabupaten),function(kabupaten){return _c('option',{key:kabupaten.nama_kabupaten,domProps:{"value":kabupaten.nama_kabupaten}},[_vm._v(" "+_vm._s(kabupaten.nama_kabupaten)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3 col-xs-6"},[_c('div',{staticClass:"form-group"},[_vm._m(3),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kecamatan),expression:"form.kecamatan"}],staticClass:"form-control m-b-5",class:{
                    'is-invalid': _vm.formValidate.kecamatan,
                  },attrs:{"name":"kecamatan"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kecamatan", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getKelurahan($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiKecamatan),function(kecamatan){return _c('option',{key:kecamatan.nama_kecamatan,domProps:{"value":kecamatan.nama_kecamatan}},[_vm._v(" "+_vm._s(kecamatan.nama_kecamatan)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3 col-xs-6"},[_c('div',{staticClass:"form-group"},[_vm._m(4),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kelurahan),expression:"form.kelurahan"}],staticClass:"form-control m-b-5",class:{
                    'is-invalid': _vm.formValidate.kelurahan,
                  },attrs:{"name":"kelurahan"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "kelurahan", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.opsiKelurahan),function(kelurahan){return _c('option',{key:kelurahan.nama_kelurahan,domProps:{"value":kelurahan.nama_kelurahan}},[_vm._v(" "+_vm._s(kelurahan.nama_kelurahan)+" ")])})],2)])]),_c('div',{staticClass:"col-md-3 col-xs-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tps"}},[_vm._v("TPS")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tps),expression:"form.tps"}],staticClass:"form-control m-b-5",class:{
                    'is-invalid': _vm.formValidate.tps,
                  },attrs:{"type":"text","name":"tps","placeholder":"Contoh: 001"},domProps:{"value":(_vm.form.tps)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "tps", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3 col-xs-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tanggal"}},[_vm._v("Tanggal")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tanggal),expression:"form.tanggal"}],staticClass:"form-control m-b-5",class:{
                    'is-invalid': _vm.formValidate.tanggal,
                  },attrs:{"type":"date","name":"tanggal"},domProps:{"value":(_vm.form.tanggal)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "tanggal", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-3 col-xs-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"waktu"}},[_vm._v("Jam")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.waktu),expression:"form.waktu"}],staticClass:"form-control m-b-5",class:{
                    'is-invalid': _vm.formValidate.waktu,
                  },attrs:{"type":"time"},domProps:{"value":(_vm.form.waktu)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "waktu", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"deskripsi"}},[_vm._v("Deskripsi")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.deskripsi),expression:"form.deskripsi"}],staticClass:"form-control m-b-5",class:{
                    'is-invalid': _vm.formValidate.deskripsi,
                  },attrs:{"rows":"10"},domProps:{"value":(_vm.form.deskripsi)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "deskripsi", $event.target.value)}}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v("Bukti Kecurangan")]),_c('br'),_vm._l((_vm.fileInputs),function(fileInput,index){return _c('div',{key:index},[_c('input',{staticClass:"btn btn-sm btn-success m-b-3 m-r-5",attrs:{"type":"file","name":'file_dokumen[' + index + ']',"accept":"image/*"},on:{"change":function($event){return _vm.onFileChange($event, index)}}}),_c('a',{staticClass:"btn btn-danger m-b-3",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.removeFileInput(index)}}},[_c('i',{staticClass:"fa fa-trash"})])])}),_c('a',{staticClass:"btn btn-info m-b-3",attrs:{"href":"javascript:;"},on:{"click":_vm.addFileInput}},[_vm._v("Tambah Gambar Bukti")])],2)])])]),_c('div',{staticClass:"panel-footer"},[_c('button',{staticClass:"btn btn-success mt-4",attrs:{"type":"button","disabled":this.loading},on:{"click":function($event){return _vm.postData()}}},[(this.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$route.params.id ? "Update" : "KIRIM DATA")+" ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Laporan Kecurangan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"provinsi"}},[_vm._v("Provinsi "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"kabupaten"}},[_vm._v("Kabupaten "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"kecamatan"}},[_vm._v("Kecamatan "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"kelurahan"}},[_vm._v("Kelurahan/Desa "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
}]

export { render, staticRenderFns }