<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title bold">Suara Masuk</h3>
        </div>
        <div class="panel-body">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :lineNumbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }"
          >
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-default m-r-4"
                >
                  <i class="fa fa-redo"></i>
                </button>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'tgl'">{{
                formatDateTime(props.row.tanggal)
              }}</span>
              <span v-if="props.column.field == 'action'">
                <div>
                  <a
                    :href="props.row.dokumen"
                    class="btn btn-sm btn-info m-r-3"
                    target="_blank"
                  >
                    <i class="fa fa-file"></i>
                  </a>
                  <button
                    class="btn btn-sm btn-danger"
                    v-on:click="confirmDelete(props.row.id_paslon)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
const ExcelJS = require("exceljs");
import moment from "moment";

export default {
  data() {
    return {
      columns: [
        {
          label: "Tanggal",
          field: "tgl",
          thClass: "text-center align-middle text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Provinsi",
          field: "provinsi",
          thClass: "text-center align-middle text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Kabupaten",
          field: "kabupaten",
          thClass: "text-center align-middle text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Kecamatan",
          field: "kecamatan",
          thClass: "text-center align-middle text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Kelurahan",
          field: "kelurahan",
          thClass: "text-center align-middle text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "TPS",
          field: "tps",
          thClass: "text-center align-middle text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Jumlah DPT",
          field: "jumlah_dpt",
          thClass: "text-center align-middle text-wrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Suara Tidak Sah",
          field: "suara_tidak_sah",
          thClass: "text-center align-middle text-wrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Anies - Muhaimin",
          field: "A001",
          thClass: "text-center align-middle text-wrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Ganjar - ? ",
          field: "A002",
          thClass: "text-center align-middle text-wrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Prabowo - ?",
          field: "A003",
          thClass: "text-center align-middle text-wrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "100px",
          thClass: "text-center text-wrap  align-middle",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 align-middle text-nowrap",
        },
      ],
      rows: null,
      isAdmin: true,
    };
  },
  created() {
    const user = localStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
      this.levelUser = this.user.level;
      if (this.levelUser !== "Admin") {
        this.isAdmin = false;
      }
    } else {
      // this.$router.push("/login");
    }
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      // this.rows = [];
      axios
        .get("/api/suara")
        .then((response) => {
          // console.log(response.data.data);
          this.rows = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    editRow(id) {
      window.alert(id);
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/api/suara/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
      // window.alert("Hapus Data dengan id: " + id);
    },

    confirmCard(id) {
      Swal.fire({
        title: "Apakah Anda Akan Mengunduh Kartu Anggota ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya!",
      }).then((result) => {
        if (result.value) {
          this.downloadCard(id);
        }
      });
    },
    downloadCard(id) {
      try {
        axios
          .get("/api/profil/card/" + id)
          .then((response) => {
            if (response.status === 200) {
              const imageUrl = response.data.card;
              fetch(imageUrl)
                .then((response) => response.blob())
                .then((blob) => {
                  const url = window.URL.createObjectURL(new Blob([blob]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", "kartu_anggota.png");
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    toggleUser(id, status) {
      var toggleLink = "/api/saksi/toggleOn/" + id;
      console.log(status);
      if (status == 1) {
        toggleLink = "/api/saksi/toggleOff/" + id;
      }
      try {
        axios
          .get(toggleLink)
          .then((response) => {
            console.log(response.status);
            if (response.status === 201) {
              this.fetchData();
              // this.isChecked = true;
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    downloadExcel() {
      axios
        .get("/api/saksi")
        .then((response) => {
          let data = response.data.data;

          let dataArray = data.map((item) => ({
            "Nomor Urut": item.no_pendukung || " ",
            Nama: item.nama_lengkap || " ",
            "No. HP": item.no_handphone || " ",
            Provinsi: item.provinsi || " ",
            Kabupaten: item.kabupaten || " ",
            Kecamatan: item.kecamatan || " ",
            "Kelurahan/Desa": item.kelurahan || " ",
            "RW/Dusun": item.rw || " ",
            TPS: item.tps || " ",
          }));

          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Data Saksi");

          const columnWidths = [
            { header: "Nomor Urut", key: "Nomor Urut", width: 12 },
            { header: "Nama", key: "Nama", width: 30 },
            { header: "No. HP", key: "No. HP", width: 17 },
            { header: "Provinsi", key: "Provinsi", width: 16 },
            { header: "Kabupaten", key: "Kabupaten", width: 16 },
            { header: "Kecamatan", key: "Kecamatan", width: 16 },
            { header: "Kelurahan/Desa", key: "Kelurahan/Desa", width: 16 },
            { header: "RW/Dusun", key: "RW/Dusun", width: 10 },
            { header: "TPS", key: "TPS", width: 6 },
          ];

          worksheet.columns = columnWidths;

          // Mengisi data ke dalam worksheet seperti yang sudah Anda cantumkan
          dataArray.forEach((item) => {
            worksheet.addRow(item);
          });

          // Mengatur header dengan font bold dan rata tengah (centered)
          const headerRow = worksheet.getRow(1);
          headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = { horizontal: "center" };
          });

          // Mengatur border pada sel-sel di worksheet
          const borderStyle = {
            style: "thin",
            color: { argb: "000000" }, // Hitam
          };

          worksheet.eachRow((row) => {
            row.eachCell((cell) => {
              cell.border = {
                top: borderStyle,
                left: borderStyle,
                bottom: borderStyle,
                right: borderStyle,
              };
            });
          });

          // Mengkonversi workbook menjadi bentuk binary (buffer)
          return workbook.xlsx.writeBuffer();
        })
        .then((excelBuffer) => {
          // Mengunduh file Excel
          this.saveAsExcelFile(excelBuffer, "data-saksi.xlsx");
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    saveAsExcelFile(buffer, filename) {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      if (navigator.msSaveBlob) {
        // Untuk Internet Explorer atau Edge
        navigator.msSaveBlob(blob, filename);
      } else {
        // Untuk browser modern
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      }
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
  },
};
</script>