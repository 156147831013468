<template>
  <div class="row">
    <div class="col-md-6">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title bold">Detail Kecurangan</h3>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-12">
              <table class="table table-striped">
                <tr>
                  <td>Tanggal, Waktu</td>
                  <td style="width: 5px">:</td>
                  <td>
                    {{ formatDateTime(detail.tanggal) }},
                    {{ detail.waktu }}
                  </td>
                </tr>
                <tr>
                  <td>Provinsi</td>
                  <td>:</td>
                  <td>{{ detail.provinsi }}</td>
                </tr>
                <tr>
                  <td>Kabupaten</td>
                  <td>:</td>
                  <td>{{ detail.kabupaten }}</td>
                </tr>
                <tr>
                  <td>Kecamatan</td>
                  <td>:</td>
                  <td>{{ detail.kecamatan }}</td>
                </tr>
                <tr>
                  <td>Kelurahan</td>
                  <td>:</td>
                  <td>{{ detail.kelurahan }}</td>
                </tr>
                <tr>
                  <td>TPS</td>
                  <td>:</td>
                  <td>{{ detail.tps }}</td>
                </tr>
                <tr>
                  <td>Deskripsi Kecurangan</td>
                  <td>:</td>
                  <td>{{ detail.deskripsi }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="panel panel-primary">
        <div class="panel-heading">
          <h3 class="panel-title bold">Bukti Kecurangan</h3>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-12 m-b-5">
              <button
                @click="lihatBukti(detail.kode_kecurangan)"
                class="btn btn-info btn-block"
              >
                Lihat Bukti Kecurangan
              </button>
            </div>
            <div
              class="col-md-4 m-b-5"
              v-for="bukti in buktiKecurangan"
              :key="bukti.id_kecurangan_file"
            >
              <div class="img-box">
                <div v-if="loading" class="skeleton-screen"></div>
                <a :href="bukti.file_link" target="_blank"
                  ><img
                    id="myImage"
                    style="height: 100%"
                    :src="bukti.file_link"
                    alt="myImage"
                    @load="contentLoaded"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      detail: {
        id_kecurangan: "",
        kode_kecurangan: "",
        tanggal: "",
        waktu: "",
        provinsi: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
        tps: "",
        deskripsi: "",
        dokumen: "",
      },
      buktiKecurangan: [],
      loading: true,
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },

  mounted() {
    const id = this.$route.params.id;
    this.detail.id_pengumuman = id;
    this.fetchData();
  },
  methods: {
    contentLoaded() {
      this.loading = false;
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    fetchData() {
      axios
        .get("/api/kecurangan/detail/" + this.detail.id_pengumuman)
        .then((response) => {
          for (let key in this.detail) {
            console.log(response.data.data[key]);
            this.detail[key] = response.data.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    lihatBukti(params) {
      this.buktiKecurangan = [];
      axios
        .get("/api/kecurangan/bukti/" + params)
        .then((response) => {
          this.buktiKecurangan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
<style>
.img-box {
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.skeleton-screen {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
</style>

