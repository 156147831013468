<script>
const sidebarMenu = [
  { path: "/dashboard", icon: "fa fa-th", title: "Dashboard" },
  { path: "/saksi", icon: "fa fa-address-card", title: "Saksi" },
  { path: "/paslon", icon: "fa fa-users", title: "Paslon" },
  { path: "/perolehan-suara", icon: "fa fa-list-ol", title: "Perolehan Suara" },
  {
    path: "/laporan-kecurangan",
    icon: "fa fa-newspaper",
    title: "Laporan Kecurangan",
  },
];

export default sidebarMenu;
</script>
